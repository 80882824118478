/* eslint-disable */
import { configureStore } from '@reduxjs/toolkit'
import setReducer from 'src/redux/sidebar/setReducer'
import userTypeReducer from './redux/usertype/UserTypeSlice'
import navigationReducer from './redux/RolesReducer/RolesSlice'
import dataForEditRoleFlagReducer from './redux/StorageDataForEditRoleReducer/StorageDataForEditRoleReducerFlagSlice'
import tokenSlice from './redux/token/tokenSlice'

const store = configureStore({
  reducer: {
    user: userTypeReducer,
    nav: navigationReducer,
    dataFlag: dataForEditRoleFlagReducer,
    sidebar: setReducer,
    tokenModal: tokenSlice,
  },
})
export default store
