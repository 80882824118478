/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showModal: false,
}

export const tokenSlice = createSlice({
  name: 'tokenModal',
  initialState: { tokenModal: initialState },
  reducers: {
    showTokenModal: (state, action) => {
      state.tokenModal = action.payload
    },
  },
})

export const { showTokenModal } = tokenSlice.actions

export default tokenSlice.reducer
